<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-adjustPoint">
          <div class="row">
            <div class="col-6 page-header">
              <img :src="require('@/assets/images/ico-import.svg')" alt="" />
              {{ lbl['import-title'] }}
            </div>
            <div class="col-6 page-setting text-right">
              {{ lbl['import-mode-sub-header'] }}:
              {{ campaignmode }}
              <img
                v-if="!isAlreadyPoint || !isAlreadyStamp"
                class="page-setting-icon"
                :src="require('@/assets/images/plan-reward-setting.png')"
                alt=""
              />
            </div>
          </div>

          <div class="text-center my-3" ng-controller="ImportPointCtrl">
            <div class="btn-group" role="group" aria-label="Button group">
              <a-button-group>
                <a-button @click="goto('AdjustPoint')">
                  {{ lbl['import-point-menu'] }}
                </a-button>
                <a-button type="primary">
                  {{ lbl['import-log-menu'] }}
                </a-button>
              </a-button-group>
            </div>
            <div class="wrap-table">
              <a-table
                row-key="RowKey"
                :columns="columns"
                :data-source="data"
                :pagination="{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                  pageSizeOptions: pageSizeOptions,
                  pageSize: defaultPageSize,
                  defaultCurrent: 1,
                  showSizeChanger: true,
                  hideOnSinglePage: false,
                  onChange: onChangePage,
                }"
                :customRow="openDetail"
              >
                <template slot="date" slot-scope="text">
                  {{ dateShortFormatUTC(text) }}
                </template>
                <template slot="status" slot-scope="text, record">
                  {{ record.success ? lbl['success'] : lbl['fail'] }}
                </template>
              </a-table>
              <div class="recording-start-container">
                <div class="recording-start">
                  {{ lbl['import-search-start-recording'] }}
                </div>
              </div>
            </div>
          </div>
          <a-modal
            v-model="visibleDetail"
            :title="lbl['import-log-dtl-title']"
            :footer="null"
            centered
            class="modal-Detail"
            :class="[currentLocale]"
          >
            <a-table
              row-key="RowKey"
              :columns="columnsDetail"
              :data-source="dataDetail"
              :pagination="{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                pageSizeOptions: pageSizeOptions,
                pageSize: defaultPageSize,
                defaultCurrent: 1,
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: onChangePageDetail,
              }"
            >
              <template slot="status" slot-scope="text, record">
                {{ record.success ? lbl['success'] : lbl['not_success'] }}
              </template>
            </a-table>
            <div class="div-import mb-3">
              <a-button class="button-import mr-4" @click="exportSuccess()">
                <img
                  class="img-fluid ico-excel"
                  :src="require('@/assets/images/icon-excel@2x.png')"
                  alt=""
                />
                <span class="pl-2">{{ lbl['import-log-import-success'] }}</span>
              </a-button>

              <a-button class="button-import mr-4" @click="exportFail()">
                <img
                  class="img-fluid ico-excel"
                  :src="require('@/assets/images/icon-excel@2x.png')"
                  alt=""
                />
                <span class="pl-2">{{ lbl['import-log-import-fail'] }}</span>
              </a-button>
            </div>
          </a-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import _ from 'lodash'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsImport from '@/core/Import/service/BzbsImport'
import XLSX from 'xlsx'
import Header from '@/components/Layout/Header'
import Locale from '@/helper/locale.js'

const columns = [
  {
    title: 'File Name',
    dataIndex: 'filename',
    key: 'import-log-file-name',
    width: '40%',
    align: 'left',
  },
  {
    title: 'Import date',
    dataIndex: 'Timestamp',
    key: 'import-log-import-date',
    width: '30%',
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: 'Success',
    key: 'import-log-import-success',
    dataIndex: 'SuccessCount',
  },
  {
    title: 'Fail',
    key: 'import-log-import-fail',
    dataIndex: 'FailCount',
  },
]

const columnsDetail = [
  {
    title: 'UserID',
    dataIndex: 'userid',
    key: 'import-log-userid-header',
    width: '26%',
  },
  {
    title: 'First Name',
    dataIndex: 'firstname',
    key: 'import-log-name-header',
    width: '15%',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastname',
    key: 'import-log-lastname-header',
    width: '15%',
  },
  {
    title: 'Import status',
    dataIndex: 'success',
    key: 'import-log-status-header',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: 'Point/Stamp on hand',
    dataIndex: 'point',
    key: 'import-log-point-header',
    width: '15%',
  },
  {
    title: 'Description',
    dataIndex: 'message',
    key: 'import-log-desc-header',
  },
]
export default {
  name: 'Log',
  mixins: [Mixin],
  components: {
    Header,
  },
  data: function () {
    return {
      currentLocale: Locale.getLocaleShort(),
      isAlreadyPoint: false,
      isAlreadyStamp: false,
      allowImportPoint: true,
      campaignmode: 'Point',
      columns,
      data: [],
      total: null,
      current: 1,
      columnsDetail,
      dataDetail: [],
      totalDetail: null,
      currentDetail: 1,
      visibleDetail: false,
      partionkey: '',
      filename: '',
      pageSize: 30,
      pageSizeDetail: 30,
    }
  },
  created() {
    this.handleFooter(true)
    this.getLogPoint(0)
    this.getCRMPlusProfile().then(result => {
      if (typeof result.IsAlreadyPoint == 'boolean') {
        this.isAlreadyPoint = result.IsAlreadyPoint
      }
      if (typeof result.IsAlreadyStamp == 'boolean') {
        this.isAlreadyStamp = result.IsAlreadyStamp
      }
      if (typeof result.AllowImportPoint == 'boolean') {
        this.allowImportPoint = result.AllowImportPoint
      }
      if (this.isAlreadyPoint) {
        this.campaignmode = this.lbl['import-mode-point']
      } else {
        this.campaignmode = this.lbl['import-mode-stamp']
      }
    })
    this.setLocale()
  },
  methods: {
    setLocale() {
      _.forEach(this.columns, column => {
        column.title = this.lbl[column.key]
      })
      _.forEach(this.columnsDetail, column => {
        column.title = this.lbl[column.key]
      })
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    getLogPoint() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsImport.getLogPoint(0)
          .then(res => {
            this.handleLoading(false)
            this.total = res.data.Count
            this.data = res.data.Serials
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    getLogPointDetail() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsImport.getLogPointDetail(this.partionkey, this.filename, 0)
          .then(res => {
            this.handleLoading(false)
            this.totalDetail = res.data.Count
            this.dataDetail = res.data.Serials
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    onChangePage(page) {
      this.current = page
    },
    onChangePageDetail(page) {
      this.currentDetail = page
    },
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    openDetail(record) {
      return {
        on: {
          click: () => {
            this.partionkey = record.PartitionKey + '|' + record.RowKey
            this.filename = record.filename
            this.getLogPointDetail(0)
            this.$nextTick(() => {
              this.visibleDetail = true
            })
          },
        },
      }
    },
    exportSuccess() {
      console.log('exportSuccess')
      this.handleLoading(true)
      let response = []

      BzbsImport.getLogPointDetailExportExcel(this.partionkey, this.filename, 0)
        .then(res => {
          this.handleLoading(false)
          response = _.filter(res.data.Serials, item => {
            if (item.success == true) return item
          })

          const data = _.map(response, item => {
            let status = ''
            if (item.success == true) {
              status = this.lbl['success']
            } else {
              status = this.lbl['not_success']
            }

            var user_id = this.lbl['import-log-userid-header']
            var first_name = this.lbl['import-log-name-header']
            var last_name = this.lbl['import-log-lastname-header']
            var status_st = this.lbl['import-log-status-header']
            var point = this.lbl['import-log-point-header']
            var description = this.lbl['import-log-desc-header']

            return {
              [user_id]: item.userid,
              [first_name]: item.firstname,
              [last_name]: item.lastname,
              [status_st]: status,
              [point]: { t: 'n', v: item.point, z: '#,##0.00' },
              [description]: item.message,
            }
          })

          if (data.length <= 10000) {
            const dataWS = XLSX.utils.json_to_sheet(data)
            var wscols = [
              { wch: 44 },
              { wch: 15 },
              { wch: 15 },
              { wch: 12 },
              { wch: 12 },
              { wch: 20 },
            ]
            dataWS['!cols'] = wscols
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, dataWS)
            XLSX.writeFile(wb, 'export_adjust_success.xlsx')
          } else {
            for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
              var data_WS = XLSX.utils.json_to_sheet(
                data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
                { dense: true },
              )

              var ws_cols = [
                { wch: 44 },
                { wch: 15 },
                { wch: 15 },
                { wch: 12 },
                { wch: 12 },
                { wch: 20 },
              ]
              data_WS['!cols'] = ws_cols
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, data_WS)
              XLSX.writeFile(wb, 'export_adjust_success_' + (i + 1) + '.xlsx')
            }
          }
        })
        .catch(error => {
          this.handleLoading(false)
          console.log('apiGetAppCategoryList error', error)
        })
    },
    exportFail() {
      console.log('exportFail')
      this.handleLoading(true)
      let response = []

      BzbsImport.getLogPointDetailExportExcel(this.partionkey, this.filename, 0)
        .then(res => {
          this.handleLoading(false)
          response = _.filter(res.data.Serials, item => {
            if (item.success == false) return item
          })

          const data = _.map(response, item => {
            let status = ''
            if (item.success == true) {
              status = this.lbl['success']
            } else {
              status = this.lbl['not_success']
            }

            var user_id = this.lbl['import-log-userid-header']
            var first_name = this.lbl['import-log-name-header']
            var last_name = this.lbl['import-log-lastname-header']
            var status_st = this.lbl['import-log-status-header']
            var point = this.lbl['import-log-point-header']
            var description = this.lbl['import-log-desc-header']

            return {
              [user_id]: item.userid,
              [first_name]: item.firstname,
              [last_name]: item.lastname,
              [status_st]: status,
              [point]: item.point,
              [description]: item.message,
            }
          })

          if (data.length <= 10000) {
            const dataWS = XLSX.utils.json_to_sheet(data)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, dataWS)
            XLSX.writeFile(wb, 'export_adjust_fail.xlsx')
          } else {
            for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
              var data_WS = XLSX.utils.json_to_sheet(
                data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
                { dense: true },
              )
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, data_WS)
              XLSX.writeFile(wb, 'export_adjust_fail_' + (i + 1) + '.xlsx')
            }
          }
        })
        .catch(error => {
          this.handleLoading(false)
          console.log('apiGetAppCategoryList error', error)
        })
    },

    //onlyNumber
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    onlyNumberDetail($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/style/base/common.scss';

.wrap-adjustPoint {
  .recording-start-container {
    text-align: right;
    margin-right: 8px;
  }

  .ant-pagination {
    margin: 16px 0;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    font-size: 10px;
    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: #fff7e6;
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }
  //   .box-pagination {
  //     margin-top: -52px;
  //     margin-bottom: 20px;
  //     padding-top: 0;
  //   }

  //   .box-pagination .show-page {
  //     margin-top: 10px;
  //   }

  //   .ant-table-pagination.ant-pagination {
  //     margin: 16px 60px 16px 0;
  //   }

  //   .ant-pagination-prev .ant-pagination-item-link,
  //   .ant-pagination-next .ant-pagination-item-link {
  //     font-size: 10px;
  //   }

  //   i.anticon.anticon-left {
  //     vertical-align: 0;
  //   }

  //   i.anticon.anticon-right {
  //     vertical-align: 0;
  //   }

  //   background: #fff;
  //   .wrap-table {
  //     width: 75%;
  //     margin: 15px auto 0;
  //   }

  //   .box-pagination .show-page {
  //     text-align: left;
  //   }

  //   .ant-table-body {
  //     overflow-x: auto;
  //     white-space: nowrap;
  //   }

  //   //แนวตั้ง mobile
  //   @media only screen and (max-device-width: 767px) and (orientation: portrait) {
  //     .box-pagination {
  //       display: inline-grid;
  //       width: 100%;
  //       text-align: end;
  //       margin: 0 0 20px 0;
  //     }

  //     .ant-table-pagination.ant-pagination {
  //       margin: 16px 0;
  //     }

  //     .box-pagination .show-page {
  //       text-align: end;
  //       margin-bottom: 10px;
  //       margin-top: 10px;
  //     }

  //     .wrap-table {
  //       width: 90%;
  //     }
  //   }

  //   //แนวนอน mobile
  //   @media only screen and (max-device-width: 768px) and (orientation: landscape) {
  //     .box-pagination {
  //       display: inline-grid;
  //       width: 100%;
  //       text-align: end;
  //       margin: 0 0 20px 0;
  //     }

  //     .ant-table-pagination.ant-pagination {
  //       margin: 16px 0;
  //     }

  //     .box-pagination .show-page {
  //       text-align: end;
  //       margin-bottom: 10px;
  //       margin-top: 10px;
  //     }

  //     .wrap-table {
  //       width: 90%;
  //     }
  //   }

  //   //แนวนอน mobile (iPhone x)
  //   @media only screen and (min-device-width: 768px) and (max-device-width: 812px) and (orientation: landscape) {
  //     .box-pagination {
  //       display: inline-grid;
  //       width: 100%;
  //       text-align: end;
  //       margin: 0 0 20px 0;
  //     }

  //     .ant-table-pagination.ant-pagination {
  //       margin: 16px 0;
  //     }

  //     .box-pagination .show-page {
  //       text-align: end;
  //       margin-bottom: 10px;
  //       margin-top: 10px;
  //     }

  //     .wrap-table {
  //       width: 90%;
  //     }
  //   }

  //   //แนวตั้ง iPad
  //   @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: portrait) {
  //     .box-pagination {
  //       display: inline-grid;
  //       width: 100%;
  //       text-align: end;
  //       margin: 0 0 20px 0;
  //       padding-top: 0px;
  //     }

  //     .ant-table-pagination.ant-pagination {
  //       margin: 16px 0;
  //     }

  //     .box-pagination .show-page {
  //       text-align: end;
  //       margin-bottom: 10px;
  //       margin-top: 10px;
  //     }
  //   }
  // }

  // .modal-Detail {
  //   .box-pagination {
  //     margin-top: -52px;
  //     margin-bottom: 20px;
  //     padding-top: 0;
  //   }

  //   .box-pagination .show-page {
  //     margin-top: 10px;
  //   }

  //   .ant-table-pagination.ant-pagination {
  //     margin: 16px 60px 16px 0;
  //   }

  //   .ant-pagination-prev .ant-pagination-item-link,
  //   .ant-pagination-next .ant-pagination-item-link {
  //     font-size: 10px;
  //   }

  //   i.anticon.anticon-left {
  //     vertical-align: 0;
  //   }

  //   i.anticon.anticon-right {
  //     vertical-align: 0;
  //   }

  //   .ant-modal {
  //     width: 90% !important;
  //     max-width: 1140px;
  //   }

  //   .ico-excel {
  //     background-color: #217346;
  //     height: 1.5vw;
  //     padding: 0.25vw;
  //   }

  //   .button-import {
  //     height: 45px;
  //     padding: 5px;
  //     width: 130px;
  //     background-color: #ebecf0;
  //     border-color: #ebecf0;
  //     color: #000;
  //   }

  //   .button-import:hover {
  //     background-color: #e5e5e5;
  //     border-color: #e5e5e5;
  //   }

  //   .div-import {
  //     float: left;
  //   }

  //   .ant-table-body {
  //     overflow-x: auto;
  //     white-space: nowrap;
  //   }

  //   //แนวตั้ง mobile
  //   @media only screen and (max-device-width: 767px) and (orientation: portrait) {
  //     .box-pagination {
  //       display: inline-grid;
  //       width: 100%;
  //       text-align: end;
  //       margin: 0 0 20px 0;
  //       padding-top: 0px;
  //     }

  //     .ant-table-pagination.ant-pagination {
  //       margin: 16px 0;
  //     }

  //     .wrap-table {
  //       width: 90%;
  //     }

  //     .button-import {
  //       margin-top: 15px;
  //     }

  //     .ico-excel {
  //       background-color: #217346;
  //       padding: 1.25vw;
  //       width: 25%;
  //       height: 100%;
  //     }

  //     .div-import {
  //       float: initial !important;
  //       text-align: center !important;
  //     }
  //   }

  //   //แนวนอน mobile
  //   @media only screen and (max-device-width: 768px) and (orientation: landscape) {
  //     .box-pagination {
  //       display: inline-grid;
  //       width: 100%;
  //       text-align: end;
  //       margin: 0 0 20px 0;
  //       padding-top: 0px;
  //     }

  //     .ant-table-pagination.ant-pagination {
  //       margin: 16px 0;
  //     }

  //     .wrap-table {
  //       width: 90%;
  //     }

  //     .button-import {
  //       margin-top: 15px;
  //     }

  //     .ico-excel {
  //       background-color: #217346;
  //       padding: 1.25vw;
  //       width: 30%;
  //       height: 100%;
  //     }

  //     .div-import {
  //       float: initial !important;
  //       text-align: center !important;
  //     }
  //   }

  //   //แนวนอน mobile (iPhone x)
  //   @media only screen and (min-device-width: 768px) and (max-device-width: 812px) and (orientation: landscape) {
  //     .ant-modal {
  //       width: 80% !important;
  //     }

  //     .box-pagination {
  //       display: inline-grid;
  //       width: 100%;
  //       text-align: end;
  //       margin: 0 0 20px 0;
  //       padding-top: 0px;
  //     }

  //     .ant-table-pagination.ant-pagination {
  //       margin: 16px 0;
  //     }

  //     .wrap-table {
  //       width: 90%;
  //     }

  //     .button-import {
  //       margin-top: 15px;
  //     }

  //     .ico-excel {
  //       background-color: #217346;
  //       padding: 1.25vw;
  //       width: 30%;
  //       height: 100%;
  //     }

  //     .div-import {
  //       float: initial !important;
  //       text-align: center !important;
  //     }
  //   }

  //   //แนวตั้ง iPad
  //   @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: portrait) {
  //     .ant-modal {
  //       width: 80% !important;
  //     }

  //     .box-pagination {
  //       display: inline-grid;
  //       width: 100%;
  //       text-align: end;
  //       margin: 0 0 20px 0;
  //       padding-top: 0px;
  //     }

  //     .ant-table-pagination.ant-pagination {
  //       margin: 16px 0;
  //     }

  //     .box-pagination .show-page {
  //       text-align: end;
  //       margin-bottom: 10px;
  //     }

  //     .button-import {
  //       margin-top: 15px;
  //     }

  //     .div-import {
  //       float: initial !important;
  //       text-align: center !important;
  //     }

  //     .ico-excel {
  //       background-color: #217346;
  //       padding: 0.5vw;
  //       width: 30%;
  //       height: 100%;
  //     }
  //   }

  //   //แนวนอน iPad
  //   @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: landscape) {
  //     .ant-modal {
  //       width: 80% !important;
  //     }

  //     .box-pagination {
  //       display: inline-grid;
  //       width: 100%;
  //       text-align: end;
  //       margin: 0 0 20px 0;
  //       padding-top: 0px;
  //     }

  //     .ant-table-pagination.ant-pagination {
  //       margin: 16px 0;
  //     }

  //     .box-pagination .show-page {
  //       text-align: end;
  //       margin-bottom: 10px;
  //     }

  //     .button-import {
  //       margin-top: 15px;
  //     }

  //     .div-import {
  //       float: initial !important;
  //       text-align: center !important;
  //     }

  //     .ico-excel {
  //       background-color: #217346;
  //       padding: 0.5vw;
  //       width: 30%;
  //       height: 100%;
  //     }
  //   }
}
.modal-Detail {
  .ant-table-wrapper {
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.65);
      font-size: 10px;
      &:hover {
        color: $primary-hover !important;
        border-color: $primary-hover !important;
      }
    }

    .ant-select-dropdown-menu-item:hover,
    .ant-select-dropdown-menu-item-selected {
      background-color: #fff7e6;
    }

    .ant-pagination-item-active,
    .ant-pagination-item:focus,
    .ant-pagination-item:hover {
      border-color: $primary-hover !important;
    }
  }
}
</style>
