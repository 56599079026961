var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('Header'),_c('div',{staticClass:"mx-auto mt-1 content-outer-container",class:{ 'content-dashboard': _vm.$store.state.isDashboard }},[_c('div',{staticClass:"mx-auto content-inner-container"},[_c('div',{staticClass:"wrap-adjustPoint"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 page-header"},[_c('img',{attrs:{"src":require('@/assets/images/ico-import.svg'),"alt":""}}),_vm._v(" "+_vm._s(_vm.lbl['import-title'])+" ")]),_c('div',{staticClass:"col-6 page-setting text-right"},[_vm._v(" "+_vm._s(_vm.lbl['import-mode-sub-header'])+": "+_vm._s(_vm.campaignmode)+" "),(!_vm.isAlreadyPoint || !_vm.isAlreadyStamp)?_c('img',{staticClass:"page-setting-icon",attrs:{"src":require('@/assets/images/plan-reward-setting.png'),"alt":""}}):_vm._e()])]),_c('div',{staticClass:"text-center my-3",attrs:{"ng-controller":"ImportPointCtrl"}},[_c('div',{staticClass:"btn-group",attrs:{"role":"group","aria-label":"Button group"}},[_c('a-button-group',[_c('a-button',{on:{"click":function($event){return _vm.goto('AdjustPoint')}}},[_vm._v(" "+_vm._s(_vm.lbl['import-point-menu'])+" ")]),_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.lbl['import-log-menu'])+" ")])],1)],1),_c('div',{staticClass:"wrap-table"},[_c('a-table',{attrs:{"row-key":"RowKey","columns":_vm.columns,"data-source":_vm.data,"pagination":{
                showTotal: function (total, range) { return ((range[0]) + "-" + (range[1]) + " " + (_vm.lbl['pagination-of-text']) + " " + total); },
                pageSizeOptions: _vm.pageSizeOptions,
                pageSize: _vm.defaultPageSize,
                defaultCurrent: 1,
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: _vm.onChangePage,
              },"customRow":_vm.openDetail},scopedSlots:_vm._u([{key:"date",fn:function(text){return [_vm._v(" "+_vm._s(_vm.dateShortFormatUTC(text))+" ")]}},{key:"status",fn:function(text, record){return [_vm._v(" "+_vm._s(record.success ? _vm.lbl['success'] : _vm.lbl['fail'])+" ")]}}])}),_c('div',{staticClass:"recording-start-container"},[_c('div',{staticClass:"recording-start"},[_vm._v(" "+_vm._s(_vm.lbl['import-search-start-recording'])+" ")])])],1)]),_c('a-modal',{staticClass:"modal-Detail",class:[_vm.currentLocale],attrs:{"title":_vm.lbl['import-log-dtl-title'],"footer":null,"centered":""},model:{value:(_vm.visibleDetail),callback:function ($$v) {_vm.visibleDetail=$$v},expression:"visibleDetail"}},[_c('a-table',{attrs:{"row-key":"RowKey","columns":_vm.columnsDetail,"data-source":_vm.dataDetail,"pagination":{
              showTotal: function (total, range) { return ((range[0]) + "-" + (range[1]) + " " + (_vm.lbl['pagination-of-text']) + " " + total); },
              pageSizeOptions: _vm.pageSizeOptions,
              pageSize: _vm.defaultPageSize,
              defaultCurrent: 1,
              showSizeChanger: true,
              hideOnSinglePage: false,
              onChange: _vm.onChangePageDetail,
            }},scopedSlots:_vm._u([{key:"status",fn:function(text, record){return [_vm._v(" "+_vm._s(record.success ? _vm.lbl['success'] : _vm.lbl['not_success'])+" ")]}}])}),_c('div',{staticClass:"div-import mb-3"},[_c('a-button',{staticClass:"button-import mr-4",on:{"click":function($event){return _vm.exportSuccess()}}},[_c('img',{staticClass:"img-fluid ico-excel",attrs:{"src":require('@/assets/images/icon-excel@2x.png'),"alt":""}}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.lbl['import-log-import-success']))])]),_c('a-button',{staticClass:"button-import mr-4",on:{"click":function($event){return _vm.exportFail()}}},[_c('img',{staticClass:"img-fluid ico-excel",attrs:{"src":require('@/assets/images/icon-excel@2x.png'),"alt":""}}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.lbl['import-log-import-fail']))])])],1)],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }